import React from 'react';
import PropTypes from 'prop-types';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useTheme } from '@mui/material/styles';

function TabPanel(props) {
	const { value, index, job, ...other } = props;
  
	return (
		<div
			hidden={value !== index}
			{...other}
		>
			{
				value === index && (
					<ExperienceContainer job={job} />
				)
			}
		</div>
	);
}

TabPanel.propTypes = {
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

const ExperienceContainer = (props) => {
	const { job, ...other } = props;
    const theme = useTheme();

    const linksStyle = {
        color: theme.palette.custom.green,
    };

	return (
		<Container fixed {...other}>
			<Typography display='inline' variant='h6' sx={{ color: theme.palette.custom.lightestSlate }}>{job.JOB_TITLE} </Typography>
            <Link href={job.JOB_URL} display='inline' underline='hover' variant='h6' sx={linksStyle}>@{job.COMPANY}</Link>
            <Typography paragraph sx={{ color: theme.palette.custom.slate }}>{job.JOB_DURATION}</Typography>
            <List>
                {
                    job.JOB_DESCRIPTION.map((desc) => 
                        <ListItem>
                            <ListItemIcon>
                                <ArrowRightIcon sx={{ color: theme.palette.custom.green }} />
                            </ListItemIcon>
                            <ListItemText sx={{ color: theme.palette.custom.slate }}>{desc}</ListItemText>
                        </ListItem>
                    )
                }
            </List>
		</Container>
	);
}

export default TabPanel;
