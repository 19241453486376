import React from 'react';
import HeaderDiv from './HeaderDiv';
import Homepage from './Homepage';
import AboutDiv from './AboutDiv';
import ExperienceDiv from './ExperienceDiv';
import WorkDiv from './WorkDiv';
import Credits from './Credits';
import ContactDiv from './ContactDiv';
import { SocialLinksStackBar, SocialLinksBottomBar } from './SocialLinksBar';

import { getDesignTokens } from './helpers/constants';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
	const [mode, setMode] = React.useState('dark');

	const colorMode = React.useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((previousMode) => (previousMode === 'dark' ? 'light' : 'dark'));
			}
		}), []
	);

	const theme = React.useMemo(
		() => createTheme(getDesignTokens(mode)), [mode]
	);

  	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<HeaderDiv />
				<Homepage />
				<AboutDiv />
				<ExperienceDiv />
				<WorkDiv />
				<ContactDiv />
				<SocialLinksBottomBar />
				<SocialLinksStackBar />
				<Credits />
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
