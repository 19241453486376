import React from 'react';
import { ColorModeContext } from './App';
import { resume } from './helpers/constants';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme, styled } from '@mui/material/styles';

function HeaderMobile() {
	const theme = useTheme();
	const colorMode = React.useContext(ColorModeContext);
    const [anchorElNav, setAnchorElNav] = React.useState(null);

	const headerItems = [
		{'index': '01.', 'href': '#about_me', 'label': 'About'},
		{'index': '02.', 'href': '#experience', 'label': 'Experience'},
		{'index': '03.', 'href': '#work', 'label': 'Work'},
		{'index': '04.', 'href': '#contact', 'label': 'Contact'}
	];

	const baseBackgroundColorStyle = {
		backgroundColor: theme.palette.custom.navy,
	};

    const appBarStyle = {
        flexGrow: 1,
        display: 'flex',
        boxShadow: '0 10px 30px -10px rgba(2, 12, 27, 0.7)',
        ...baseBackgroundColorStyle,
    }
	
	const indexTypographyStyle = {
		color: theme.palette.custom.green,
		textTransform: 'capitalize',
		textDecoration: 'none',
	};
	
	const headerItemTypographyStyle = {
		color: theme.palette.custom.lightSlate,
		display: 'inline',
	}
	
	const interactiveButtonStyle = {
		border: '1px solid',
		borderRadius: 2,
		borderColor: theme.palette.custom.green,
		color: theme.palette.custom.green,
		textTransform: 'capitalize',
	};

	return (
		<AppBar position='fixed' sx={baseBackgroundColorStyle}>
			<Box sx={appBarStyle}>
                <IconButton
                    size='large'
                    aria-label='menu'
                    aria-haspopup='true'
                    aria-expanded={Boolean(anchorElNav) ? 'true' : undefined}
                    aria-controls={Boolean(anchorElNav) ? 'menu-appbar' : undefined}
                    onClick={(event) => {setAnchorElNav(event.currentTarget)}}
                >
                    <MenuIcon fontSize='inherit' sx={{ color: theme.palette.custom.green }} />
                </IconButton>
                <StyledMenu
                    id='menu-appbar'
                    anchorEl={anchorElNav}
                    open={Boolean(anchorElNav)}
                    onClose={() => {setAnchorElNav(null)}}
                >
                    {
                        headerItems.map((item) => (
                            <MenuItem key={item['label']}>
                                <Typography noWrap component="a" href={item['href']} sx={indexTypographyStyle}>
                                    {`${item['index']} `} 
                                    <Typography sx={headerItemTypographyStyle}>{item['label']}</Typography>
                                </Typography>
                            </MenuItem>
                        ))
                    }
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <MenuItem><Button sx={interactiveButtonStyle} onClick={colorMode.toggleColorMode}>Try!</Button></MenuItem>
                        <MenuItem><Button sx={interactiveButtonStyle} component='a' href={resume}>Resume</Button></MenuItem>
                    </Box>                 
                </StyledMenu>
            </Box>
		</AppBar>
	);
}
  
export default HeaderMobile;

const StyledMenu = styled((props) => (
    <Menu
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        sx={{
            display: 'block',
        }}
        {...props}
    />
)) (({ theme }) => ({
        '& .MuiPaper-root': {
            border: '1px solid',
            borderRadius: 4,
            borderColor: theme.palette.custom.green,
            background: theme.palette.custom.navy,
        },
    }
));
