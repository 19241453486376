import React from 'react';
import * as string from '../json/Homepage.json';
import { desktopComponentSettings, mobileComponentSettings, mainBlockAlignmentStyle } from './helpers/constants';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

function Homepage() {
	const theme = useTheme();

	const helloMsg = string.HELLO_MSG;
	const name = string.NAME;
	const shortIntro = string.SHORT_INTRO;
	const intro = string.INTRO;

	const mainBlockStyle = {
		color: theme.palette.custom.green,
		background: theme.palette.custom.navy,
		textAlign: 'left',
		...mainBlockAlignmentStyle,
	};
	
	const homepageStyle = {
		m: { xsmall: 1, small: 5, medium: 10, large: 15, xlarge: 15 },
		pl: { xsmall: 3, small: 10, medium: 15, large: 20, xlarge: 20 },
		pr: { xsmall: 2, small: 5, medium: 10, large: 35, xlarge: 35 },
		backgroundColor: theme.palette.custom.navy,
	}

	const subHeadingStyle1 = {
		mb: 1,
		fontWeight: 'bold',
		color: theme.palette.custom.green,
	};

	const subHeadingStyle2 = {
		mb: 2,
		fontWeight: 'bold',
		color: theme.palette.custom.lightestSlate,
	};

	const subHeadingStyle3 = {
		mb: 2,
		color: theme.palette.custom.slate,
	};
	
	const interactiveButtonStyle = {
		my: 2,
		px: 2, py: 1,
		border: 2,
		borderRadius: 2,
		borderColor: theme.palette.custom.green,
		color: theme.palette.custom.green,
		textTransform: 'capitalize',
	};

  	return (
		<Container fluid sx={mainBlockStyle}>
			<Box sx={homepageStyle}>
				<Container sx={desktopComponentSettings}>
					<Typography gutterBottom variant='h5' sx={subHeadingStyle1}>{helloMsg}</Typography>
					<Typography gutterBottom variant='h2' sx={subHeadingStyle2}>{name},</Typography>
					<Typography gutterBottom variant='h3' sx={{fontWeight: 'bold', ...subHeadingStyle3}}>{shortIntro}</Typography>
					<Typography gutterBottom variant='h4' sx={subHeadingStyle3}>{intro}</Typography>
					<Button sx={interactiveButtonStyle} component='a' href='mailto: hykelvinlee42@gmail.com'>Get In Touch</Button>
				</Container>
				<Container sx={mobileComponentSettings}>
					<Typography gutterBottom variant='h6' sx={subHeadingStyle1}>{helloMsg}</Typography>
					<Typography gutterBottom variant='h3' sx={subHeadingStyle2}>{name},</Typography>
					<Typography gutterBottom variant='h5' sx={{fontWeight: 'bold', ...subHeadingStyle3}}>{shortIntro}</Typography>
					<Typography gutterBottom variant='h6' sx={subHeadingStyle3}>{intro}</Typography>
					<Button sx={interactiveButtonStyle} component='a' href='mailto: hykelvinlee42@gmail.com'>Get In Touch</Button>
				</Container>
			</Box>
	  	</Container>
  	);
}

export default Homepage;
