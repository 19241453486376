import React from 'react';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function SectionHeader({index, title, link}) {
    const theme = useTheme();

    const style = {
        mb: 1,
        fontWeight: 'bold',
        color: theme.palette.custom.lightestSlate,
        '&::before': {
            fontSize: '.8em',
            color: theme.palette.custom.green,
            content: `"0${index}. "`,
        }
    }

    return (
        <>
            <a id={link} href='/#'>{''}</a>
            <Typography variant='h6' sx={style}>{title}</Typography>
        </>
    );
}

export default SectionHeader;
