import React from 'react';
import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';
import { desktopComponentSettings, mobileComponentSettings } from './helpers/constants';

import Paper from '@mui/material/Paper';

function HeaderDiv() {
	return (
		<>
			<Paper sx={desktopComponentSettings}>
				<HeaderDesktop />
			</Paper>
		
			<Paper sx={mobileComponentSettings}>
				<HeaderMobile />
			</Paper>
		</>
	);
}
  
export default HeaderDiv;  
