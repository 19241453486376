import React, { useState } from 'react';
import SectionHeader from './common/SectionHeader';
import TabPanel from './common/TabPanel';
import * as string from '../json/ExperienceDiv.json';
import { desktopComponentSettings, mobileComponentSettings, mainBlockAlignmentStyle } from './helpers/constants';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NativeSelect from '@mui/material/NativeSelect';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { useTheme, styled } from '@mui/material/styles';

import VisibilitySensor from 'react-visibility-sensor';

function ExperienceDiv() {
	const theme = useTheme();
	const [activeItem, setActiveItem] = useState(0);
	const [visibility, setVisibility] = useState(false);

	const tabItems = string.JOBS.map((job) =>
		<StyledTab label={job.COMPANY} />
	);

	const menuItems = string.JOBS.map((job, index) =>
        <option value={index}>{job.COMPANY}</option>
	);

	const tabItemPanels = string.JOBS.map((job, index) => 
		<TabPanel value={activeItem} index={index} job={job} />
	);

	const mainBlockStyle = {
		color: theme.palette.custom.green,
		background: theme.palette.custom.navy,
		...mainBlockAlignmentStyle,
	};

	const menuBlockStyle = {
		ml: { xsmall: 0, small: 0, medium: 0, large: 10, xlarge: 10 },
		mr: { xsmall: 0, small: 0, medium: 0, large: 10, xlarge: 10 },
		pl: { xsmall: 1, small: 1, medium: 5, large: 20, xlarge: 20 },
		pr: { xsmall: 1, small: 1, medium: 5, large: 15, xlarge: 15 },
		backgroundColor: theme.palette.custom.navy,
	};

	return (
		<VisibilitySensor partialVisibility={true} onChange={(isVisible) => { setVisibility(isVisible); }}>
			<Container fluid sx={mainBlockStyle}>
				<Box sx={menuBlockStyle}>
					<SectionHeader index={2} title={string.PAGE_TITLE} link='experience'></SectionHeader>
					<Slide direction='right' in={visibility} timeout={3000}>
						<Paper sx={{ background: 'transparent', ...desktopComponentSettings }}>
							<Box sx={{ 
									flexGrow: 1,
									display: 'flex',
									p: 5,
									pl: { ultrawide: 50 },
									pr: { ultrawide: 50 },
								}}
							>
								<StyledTabs
									orientation='vertical'
									variant='scrollable'
									value={activeItem}
									onChange={(event, value) => { setActiveItem(value); }}
									sx={{ borderRight: 1, borderColor: 'divider', backgroundColor: 'transparent', }}
								>
									{tabItems}
								</StyledTabs>
								{tabItemPanels}
							</Box>
						</Paper>
					</Slide>
					<Slide direction='right' in={visibility} timeout={3000}>
						<Paper sx={{ background: 'transparent', ...mobileComponentSettings }}>
							<Box sx={{ flexGrow: 1, display: 'flex', p: 5 }}>
								<NativeSelect
									value={activeItem}
									onChange={(event) => { setActiveItem(Number(event.target.value)); console.log(activeItem); }}
								>
									{menuItems}
								</NativeSelect>
							</Box>
							{tabItemPanels}
						</Paper>
					</Slide>
				</Box>
			</Container>
		</VisibilitySensor>
	);
}

export default ExperienceDiv;

const StyledTabs = styled((props) => (
	<Tabs {...props} />
)) (({ theme }) => ({
		'& .MuiTabs-indicator': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: theme.palette.custom.green,
		},
	}
));

const StyledTab = styled((props) => (
	<Tab {...props} />
)) (({ theme }) => ({
		textTransform: 'none',
		'&.Mui-selected': {
			fontWeight: 'bold',
			color: theme.palette.custom.green,
			background: theme.palette.custom.greenFilter,
		},
		'& .MuiTouchRipple-root': {
			color: theme.palette.custom.green,
		},
	}
));
