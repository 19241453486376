import React from 'react';
import SectionHeader from './common/SectionHeader';
import { mainBlockAlignmentStyle } from './helpers/constants';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

function ContactDiv() {
	const theme = useTheme();
	const title = 'What\'s Next?';
	const contactMsg = 'Although I\'m not currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I\'ll try my best to get back to you!';

	const mainBlockStyle = {
		// pt: 5, pb: 5,
		// whiteSpace: 'normal',
		color: theme.palette.custom.green,
		background: theme.palette.custom.navy,
		textAlign: 'center',
		...mainBlockAlignmentStyle,
	};

	const contactStyle = {
		m: { xsmall: 0, small: 0, medium: 0, large: 'auto', xlarge: 'auto' },
		pl: { xsmall: 1, small: 1, medium: 5, large: 20, xlarge: 20 },
		pr: { xsmall: 1, small: 1, medium: 5, large: 20, xlarge: 20 },
		backgroundColor: theme.palette.custom.navy,
	}

	const subHeadingStyle = {
		mb: 2,
		fontWeight: 'bold',
		color: theme.palette.custom.lightestSlate,
	};

	const bodyStyle = {
		mb: 2,
		color: theme.palette.custom.slate,
	}

	const interactiveButtonStyle = {
		my: 2,
		px: 2, py: 1,
		border: 2,
		borderRadius: 2,
		borderColor: theme.palette.custom.green,
		color: theme.palette.custom.green,
		textTransform: 'capitalize',
	};

  	return (
		<Container fluid sx={mainBlockStyle}>
			<Box sx={contactStyle}>
				<SectionHeader index={4} title={title} link='contact'></SectionHeader>
				<Typography variant='h3' sx={subHeadingStyle}>Get In Touch</Typography>
				<Typography gutterBottom variant='body2' sx={bodyStyle}>{contactMsg}</Typography>
				<Button sx={interactiveButtonStyle} component='a' href='mailto: hykelvinlee42@gmail.com'>Say Hello</Button>
			</Box>
		</Container>
  	);
}

export default ContactDiv;
