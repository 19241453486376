import React from 'react';
import { github, linkedin } from './helpers/constants';

import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export function SocialLinksStackBar() {
    const isAboveSmall = useMediaQuery((theme) => theme.breakpoints.up('small'));

    return (
        isAboveSmall ?
            <Stack sx={{ position: 'fixed', bottom: 0, right: 5, p: 5 }} spacing={2}>
                <Fab size='medium' href={linkedin}>
                    <LinkedInIcon />
                </Fab>
                <Fab size='medium' href={github}>
                    <GitHubIcon />
                </Fab>
            </Stack>
        :
            <></>
    );
}

export function SocialLinksBottomBar() {
    const theme = useTheme();
    const isBelowSmall = useMediaQuery((theme) => theme.breakpoints.down('small'));

    const linkStyle = {
        color: theme.palette.custom.lightestSlate,
        '&:hover': {
            color: theme.palette.custom.green,
        }
    };

    return (
        isBelowSmall ?
            <Stack direction='row' justifyContent='center' alignItems='center' spacing={5} sx={{ background: theme.palette.custom.navy }}>
                <Link sx={linkStyle} href={linkedin}><LinkedInIcon fontSize='large' /></Link>
                <Link sx={linkStyle} href={github}><GitHubIcon fontSize='large' /></Link>
            </Stack>
        :
            <></>
    );
}
