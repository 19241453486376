import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

function Credits() {
	const theme = useTheme();

  	const footerStyle = {
		color: theme.palette.custom.green,
		backgroundColor: theme.palette.custom.navy,
    	textAlign: 'center',
  	};

	const linkTypographyStyle = {
		color: theme.palette.custom.green,
		textDecoration: 'none',
		fontSize: 10,
	};

	return (
		<Box sx={footerStyle}>
			<Typography noWrap sx={{ fontSize: 10 }}>
				Inspired by&nbsp;
				<Typography component="a" href={'https://brittanychiang.com/'} sx={linkTypographyStyle}>
				Brittany Chiang
				</Typography>
				&nbsp;& Built by HY. Kelvin Lee
			</Typography>
		</Box>
	);
}
  
export default Credits;  
