import React from 'react';
import SectionHeader from './common/SectionHeader';
import NewlineTextDiv from './common/NewlineTextDiv';
import { desktopComponentSettings, mainBlockAlignmentStyle } from './helpers/constants';
import * as string from '../json/WorkDiv.json';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkIcon from '@mui/icons-material/Link';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';

import VisibilitySensor from 'react-visibility-sensor';

function WorkDiv() {
	const theme = useTheme();
	const animationTimeout = 3000;

	const DescriptionLeftBlock = (props) => {
		const { work, animationTimeout, ...other } = props;
		const [visibility, setVisibility] = React.useState(false);
		const image = require('../img' + work.IMAGE);
		const icon = work.GIT.search('github') !== -1 ? <GitHubIcon /> : <LinkIcon />;
		const workPaperStyle = {
			mt: 1,
			p: 1,
			background: theme.palette.custom.lightNavy,
			textAlign: 'left',
		};

		const linkStyle = {
			color: theme.palette.custom.lightestSlate,
			'&:hover': {
				color: theme.palette.custom.green,
			}
		};

		return (
			<VisibilitySensor partialVisibility={true} onChange={(isVisible) => { setVisibility(isVisible); }}>
				<Container fluid sx={{ display: 'inline-flex' }} {...other}>
					<Slide direction='left' in={visibility} timeout={animationTimeout}>
						<Box sx={{ mr: 5, }}>
							<Typography variant='h6' sx={{ color: theme.palette.custom.lightestSlate }}>{work.NAME}</Typography>
							<Paper sx={workPaperStyle}>
								<Typography paragraph sx={{ color: theme.palette.custom.lightSlate }}><NewlineTextDiv text={work.DESCRIPTION}/></Typography>
							</Paper>
							<br />
							<Link sx={linkStyle} href={work.GIT}>{icon}</Link>
						</Box>
					</Slide>
					<Slide direction='left' in={visibility} timeout={animationTimeout}>
						<Box
							component='img' 
							src={image}
							sx={{ maxHeight: 400, maxWidth: 300, ...desktopComponentSettings }}
						/>
					</Slide>
				</Container>
			</VisibilitySensor>
		);
	}

	const DescriptionRightBlock = (props) => {
		const { work, animationTimeout, ...other } = props;
		const [visibility, setVisibility] = React.useState(false);
		const image = require('../img' + work.IMAGE);
		const icon = work.GIT.search('github') !== -1 ? <GitHubIcon /> : <LinkIcon />;
		const workPaperStyle = {
			mt: 1,
			p: 1,
			background: theme.palette.custom.lightNavy,
			textAlign: 'right',
		};

		const linkStyle = {
			color: theme.palette.custom.lightestSlate,
			'&:hover': {
				color: theme.palette.custom.green,
			}
		};

		return (
			<VisibilitySensor partialVisibility={true} onChange={(isVisible) => { setVisibility(isVisible); }}>
				<Container fluid sx={{ display: 'inline-flex' }} {...other}>
					<Slide direction='right' in={visibility} timeout={animationTimeout}>
						<Box
							component='img' 
							src={image}
							sx={{ maxHeight: 400, maxWidth: 300, ...desktopComponentSettings }}
						/>
					</Slide>
					<Slide direction='right' in={visibility} timeout={animationTimeout}>
						<Box sx={{ ml: 5, textAlign: 'right', }}>
							<Typography variant='h6' sx={{ color: theme.palette.custom.lightestSlate }}>{work.NAME}</Typography>
							<Paper sx={workPaperStyle}>
								<Typography paragraph sx={{ color: theme.palette.custom.lightSlate }}><NewlineTextDiv text={work.DESCRIPTION}/></Typography>
							</Paper>
							<br />
							<Link sx={linkStyle} href={work.GIT}>{icon}</Link>
						</Box>
					</Slide>
				</Container>
			</VisibilitySensor>
		);
	}

	const stackItems = string.WORKS.map((work, index) => {
		return (
			index % 2 === 0 ? 
				<DescriptionLeftBlock work={work} animationTimeout={animationTimeout} />
			:
				<DescriptionRightBlock work={work} animationTimeout={animationTimeout} />
		);
	});

	const mainBlockStyle = {
		color: theme.palette.custom.green,
		background: theme.palette.custom.navy,
		...mainBlockAlignmentStyle,
	};

	const workStyle = {
		ml: { xsmall: 0, small: 0, medium: 0, large: 10, xlarge: 10 },
		mr: { xsmall: 0, small: 0, medium: 0, large: 10, xlarge: 10 },
		pl: { xsmall: 1, small: 1, medium: 5, large: 20, xlarge: 20 },
		pr: { xsmall: 1, small: 1, medium: 5, large: 15, xlarge: 15 },
		background: theme.palette.custom.navy,
	};

  	return (
		<Container fluid sx={mainBlockStyle}>
			<Box sx={workStyle}>
				<SectionHeader index={3} title={string.PAGE_TITLE} link='work'></SectionHeader>
				<Box 
					sx={{
						mt: 5,
						ml: { ultrawide: 50 },
						mr: { ultrawide: 50 },
					}}
				>
					<Stack spacing={5}>{stackItems}</Stack>
				</Box>
			</Box>
		</Container>
	);
}

export default WorkDiv;
