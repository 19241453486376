import React from 'react';
import SectionHeader from './common/SectionHeader';
import { desktopComponentSettings, mainBlockAlignmentStyle } from './helpers/constants';
import * as string from '../json/AboutDiv.json';
import picture from '../img/Kelvin.JPG';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';

import VisibilitySensor from 'react-visibility-sensor';

function AboutDiv() {
	const [visibility, setVisibility] = React.useState(false);
	const theme = useTheme();
	const title = 'About me';

	const paragraphItems = string.ABOUT_PARAGRAPHS.map((paragraph) =>
		<Typography sx={{ color: theme.palette.custom.slate }} paragraph>{paragraph}</Typography>
	);

	const mainBlockStyle = {
		color: theme.palette.custom.green,
		background: theme.palette.custom.navy,
		...mainBlockAlignmentStyle,
	};

	const aboutStyle = {
		m: { xsmall: 0, small: 0, medium: 0, large: 10, xlarge: 10 },
		pl: { xsmall: 1, small: 1, medium: 5, large: 20, xlarge: 20 },
		pr: { xsmall: 1, small: 1, medium: 5, large: 20, xlarge: 20 },
		backgroundColor: theme.palette.custom.navy,
	}

  	return (
		<VisibilitySensor partialVisibility={true} onChange={(isVisible) => { setVisibility(isVisible); }}>
			<Container fluid sx={mainBlockStyle}>
				<Box sx={aboutStyle}>
					<SectionHeader index={1} title={title} link='about_me'></SectionHeader>
					<Grid
						container
						wrap='nowrap'
						spacing={3}
						sx={{
							pl: { ultrawide: 20 },
							pr: { ultrawide: 20 },
						}}
					>
						<Slide direction='right' in={visibility} timeout={3000}>
							<Grid item container columns={6}>
								{paragraphItems}
								<SkillsList />
							</Grid>
						</Slide>
						<Slide direction='left' in={visibility} timeout={3000}>
							<Grid item sx={desktopComponentSettings} columns={4}>
								<Box 
									component='img' 
									src={picture}
									sx={{
										maxHeight: 400,
										maxWidth: 300,
									}}
								/>
							</Grid>
						</Slide>
					</Grid>
				</Box>
			</Container>
		</VisibilitySensor>
  	);
}

export default AboutDiv;

const Skill = ({skill}) => {
	const theme = useTheme();

	const listItemIconStyle = {
		minWidth: { xsmall: 25, small: 35, medium: 35, large: 35, xlarge: 35 },
		color: theme.palette.custom.green,
	};

	return (
		<ListItem>
			<ListItemIcon sx={listItemIconStyle}><ThumbUpOutlinedIcon /></ListItemIcon>
			<ListItemText sx={{ color: theme.palette.custom.slate }} primary={skill} />
		</ListItem>
	);
};

const SkillsList = () => {
	return (
		<>
			<Grid container wrap='nowrap' columns={{ xsmall: 2, small: 2, medium: 2, large: 2, xlarge: 2 }}>
				<Grid item container>
					<List dense>
						<Skill skill='Python' />
						<Skill skill='C/C++' />
						<Skill skill='SQL/NoSQL' />
					</List>
				</Grid>

				<Grid item container>
					<List dense>
						<Skill skill='React' />
						<Skill skill='Java' />
						<Skill skill='Swift' />
					</List>
				</Grid>
			</Grid>
		</>
	);
};
