export const resume = 'https://github.com/hykelvinlee42/resumpy/blob/main/export/resume.pdf';
export const github = 'https://github.com/hykelvinlee42/';
export const linkedin = 'https://www.linkedin.com/in/hykelvinlee/';

const responsivenessSettings = {
    breakpoints: {
        values: {
            xsmall: 0,
            small: 600,
            medium: 960,
            large: 1280,
            xlarge: 1440,
            ultrawide: 2560,
        }
    },
};

export const desktopComponentSettings = {
    display: { xsmall: 'none', small: 'none', medium: 'none', large: 'block', xlarge: 'block', ultrawide: 'block' },
    visibility: { xsmall: 'hidden', small: 'hidden', medium: 'hidden', large: 'visible', xlarge: 'visible', ultrawide: 'visible' }
};

export const mobileComponentSettings = {
    display: { xsmall: 'block', small: 'block', medium: 'block', large: 'none', xlarge: 'none', ultrawide: 'none' },
    visibility: { xsmall: 'visible', small: 'visible', medium: 'visible', large: 'hidden', xlarge: 'hidden', ultrawide: 'hidden' }
}

const typographySettings = {
    typography: {
        fontFamily: [
            'SF Mono',
            'Fira Code',
            'Fira Mono',
            'Lucida Console',
            'Monaco',
            'monospace',
        ].join(','),
    },
};

const darkmode = {
    common: {
        black: '#000',
        white: '#FFF',
    },
    primary: {
        main: '#90CAF9',
        light: '#E3F2FD',
        dark: '#42A5F5',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    secondary: {
        main: '#CE93D8',
        light: '#F3E5F5',
        dark: '#AB47BC',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
        main: '#F44336',
        light: '#E57373',
        dark: '#D32F2F',
        contrastText: '#FFF',
    },
    warning: {
        main: '#FFA726',
        light: '#FFB74D',
        dark: '#F57C00',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
        main: '#29B6F6',
        light: '#4FC3F7',
        dark: '#0288D1',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
        main: '#66BB6A',
        light: '#81C784',
        dark: '#388E3C',
        contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#F5F5F5',
        A200: '#EEE',
        A400: '#BDBDBD',
        A700: '#616161'
    },
    constractThreshold: 3,
    tonalOffset: 0.2,
    text: {
        primary: '#FFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: 'rgba(255, 255, 255, 0.5)',
        icon: 'rgba(255, 255, 255, 0.5)',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    background: {
        paper: '#121212',
        default: '#121212',
    },
    action: {
        active: '#FFF',
        hover: 'rgba(255, 255, 255, 0.08)',
        hoverOpacity: 0.08,
        selected: 'rgba(255, 255, 255, 0.16)',
        selectedOpacity: 0.16,
        disabled: 'rgba(255, 255, 255, 0.3)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(255, 255, 255, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.24
    },
    
    custom: {
        navy: '#0A192F',
        lightNavy: '#172A45',
        slate: '#8892B0',
        lightSlate: '#A8B2D1',
        lightestSlate: '#CCD6F6',
        green: '#64FFDA',
        greenFilter: 'rgba(100, 255, 218, 0.3)',
    },
};

const lightmode = {
    common: {
        black: '#000',
        white: '#FFF',
    },
    primary: {
        main: '#1976D2',
        light: '#42A5F5',
        dark: '#1565C0',
        contrastText: '#FFF',
    },
    secondary: {
        main: '#9C27B0',
        light: '#BA68C8',
        dark: '#7B1FA2',
        contrastText: '#FFF',
    },
    error: {
        main: '#D32F2F',
        light: '#EF5350',
        dark: '#C62828',
        contrastText: '#FFF',
    },
    warning: {
        main: '#ED6C02',
        light: '#FF9800',
        dark: '#E65100',
        contrastText: '#FFF',
    },
    info: {
        main: '#0288D1',
        light: '#03A9F4',
        dark: '#01579B',
        contrastText: '#FFF',
    },
    success: {
        main: '#2E7D32',
        light: '#4CAF50',
        dark: '#1B6E20',
        contrastText: '#FFF',
    },
    grey: {
        50: '#FAFAFA',
        100: '#F5F5F5',
        200: '#EEE',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#9E9E9E',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#F5F5F5',
        A200: '#EEE',
        A400: '#BDBDBD',
        A700: '#616161'
    },
    constractThreshold: 3,
    tonalOffset: 0.2,
    text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
        paper: '#FFF',
        default: '#FFF',
    },
    action: {
        active: 'rgba(0, 0, 0, 0.54)',
        hover: 'rgba(0, 0, 0, 0.04)',
        hoverOpacity: 0.04,
        selected: 'rgba(0, 0, 0, 0.08)',
        selectedOpacity: 0.08,
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.12)',
        disabledOpacity: 0.38,
        focus: 'rgba(0, 0, 0, 0.12)',
        focusOpacity: 0.12,
        activatedOpacity: 0.12
    },

    custom: {
        navy: '#EDF5FF',
        lightNavy: '#FFEDED',
        slate: '#594B3B',
        lightSlate: 'black',
        lightestSlate: 'black',
        green: '#F6B39D',
        greenFilter: 'rgba(246, 179, 157, 0.3)',
    },
};

export const getDesignTokens = (mode) => ({
    palette: {
      mode,
      ...(mode === 'dark' ? darkmode : lightmode),
    },
    ...responsivenessSettings,
    ...typographySettings,
});

export const mainBlockAlignmentStyle = {
    pt: { xsmall: 5, small: 5, medium: 5, large: 5, xlarge: 5 },
    pb: { xsmall: 5, small: 5, medium: 5, large: 5, xlarge: 5 },
    pl: { xsmall: 5, small: 5, medium: 5, large: 10, xlarge: 10, ultrawide: 60 },
    pr: { xsmall: 5, small: 5, medium: 5, large: 10, xlarge: 10, ultrawide: 60 },
    whiteSpace: 'normal',
}
