import React from 'react';
import { ColorModeContext } from './App';
import { resume } from './helpers/constants';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

function HeaderDesktop() {
	const theme = useTheme();
	const colorMode = React.useContext(ColorModeContext);

	const headerItems = [
		{'index': '01.', 'href': '#about_me', 'label': 'About'},
		{'index': '02.', 'href': '#experience', 'label': 'Experience'},
		{'index': '03.', 'href': '#work', 'label': 'Work'},
		{'index': '04.', 'href': '#contact', 'label': 'Contact'}
	];

	const baseBackgroundColorStyle = {
		backgroundColor: theme.palette.custom.navy,
	};

	const appBarStyle = {
		display: 'flex',
		justifyContent: 'space-around',
		boxShadow: '0 10px 30px -10px rgba(2, 12, 27, 0.7)',
		...baseBackgroundColorStyle,
	};
	
	const indexTypographyStyle = {
		color: theme.palette.custom.green,
		textTransform: 'capitalize',
		textDecoration: 'none',
	};
	
	const headerItemTypographyStyle = {
		color: theme.palette.custom.lightSlate,
		display: 'inline',
	}
	
	const interactiveButtonStyle = {
		my: 2,
		px: 2, py: 1,
		border: 2,
		borderRadius: 2,
		borderColor: theme.palette.custom.green,
		color: theme.palette.custom.green,
		textTransform: 'capitalize',
	};

	return (
		<AppBar position='fixed' sx={baseBackgroundColorStyle}>
			<Container maxWidth='xl' sx={baseBackgroundColorStyle}>
				<Box sx={appBarStyle}>
					{
						headerItems.map((item) => (
							<Button key={item['label']} sx={{my: 2}}>
								<Typography noWrap component="a" href={item['href']} sx={indexTypographyStyle}>
									{`${item['index']} `} 
									<Typography sx={headerItemTypographyStyle}>
										{item['label']}
									</Typography>
								</Typography>
							</Button>
						))
					}
					<Box>
						<Button sx={{mr: 0.5, ...interactiveButtonStyle}} onClick={colorMode.toggleColorMode}>Try!</Button>
						<Button sx={{ml: 0.5, ...interactiveButtonStyle}} component='a' href={resume}>Resume</Button>
					</Box>
				</Box>
			</Container>
		</AppBar>
	);
}
  
export default HeaderDesktop;  
